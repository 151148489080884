<template>
    <div class="Login"></div>
</template>

<script>
    import initUserInfo from '../utils/initUserInfo';
    import getStrParams from "../utils/getStrParams";
    import core from '../core/index'
    import {userLogin} from "../server";
    import initWXAPI from "../utils/initWXAPI";
    import WXShare from "../utils/WXShare";

    export default {
        name: "Login",
        data() {
            return {
                str: {},
                code: ''
            }
        },
        methods: {
            async getUserInfo(code, url) {
                this.code = code
                const data = await userLogin({Code: code, Session: ''})
                // alert('接口返回的sessionId:'+data.Data.SessionId)
                core.cookie.setStorage('sessionId', data.Data.SessionId)
                this.$router.replace(decodeURIComponent(url)).catch(() => {})
            },
            initPageShare() {
                initWXAPI.init(this.$store.state.url,function(){
                    const options = {
                        title: '红色故事演讲大赛',
                        desc:  '2022上海市民文化节',
                        imgUrl: 'https://redsw1.02lu.com/share_logo.png'
                    }
                    WXShare.initWXShare(options)
                    WXShare.initWXTimelineShare(options)
                })
            }
        },
        created() {
            this.initPageShare();
            const str = window.location.href.split('?')
            const query = getStrParams.getStrParams(str.length && str[1])
            if (query.code && query.redirect) {
                this.getUserInfo(query.code, query.redirect)
            } else {
                initUserInfo.init()
            }
        }
    }
</script>

<style scoped>

</style>